import React, { useEffect, useState } from 'react'
import { urlValidation } from 'helpers/urlValidation'
import { alertError } from 'api'
import { withRouter } from 'react-router-dom'
import { SERVER_URL } from 'configs'

import * as S from './WidgetDemo.style'
import { Clip } from '../../uiKit/icons/Clip'

interface Props {
  match: any
}

const WidgetDemo: React.FC<Props> = ({ match }) => {
  const [websiteUrl, setWebsite] = useState(localStorage.getItem(`widget-demo-preview-${match.params.botId}`) || '')
  const [iframeSrc, setIframeSrc] = useState(localStorage.getItem(`widget-demo-preview-${match.params.botId}`) || '')

  useEffect(() => {
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('id', 'chatbot-initials-script')
    script.setAttribute('src', `${SERVER_URL}/widget/script-chatbot.js`)
    script.setAttribute('data-server-url', `${SERVER_URL}/api`)
    script.setAttribute('data-bot-id', match.params.botId)

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const handleChangeWebsiteUrl = text => {
    setWebsite(text)
  }

  const handleClickApply = () => {
    const isError = !urlValidation(websiteUrl) || !websiteUrl.startsWith('https://')
    if (websiteUrl && isError) {
      alertError('The website URL is invalid. Make sure the URL is correct and try again.')
    } else {
      setIframeSrc(websiteUrl)
      localStorage.setItem(`widget-demo-preview-${match.params.botId}`, websiteUrl)
    }
  }

  return (
    <S.WidgetDemo>
      <S.Header>
        <S.Input
          placeholder="Enter website address to use as background"
          value={websiteUrl}
          onChange={e => handleChangeWebsiteUrl(e.target.value)}
        />
        <S.Button onClick={handleClickApply}>Apply</S.Button>
        <S.SecondaryButton id="reset-chat-button">Reset chat</S.SecondaryButton>
      </S.Header>
      <S.Body>
        {iframeSrc ? (
          <S.Iframe title="Demo website" src={iframeSrc} />
        ) : (
          <S.Wrap>
            <Clip />
            <S.SubTitle>Please enter the website address in the field above to use as background</S.SubTitle>
          </S.Wrap>
        )}
      </S.Body>
    </S.WidgetDemo>
  )
}

export default withRouter(WidgetDemo)
