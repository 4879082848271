// @ts-nocheck
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Controller } from 'react-hook-form/dist/index.ie11'
import Select, { components } from 'react-select'
import SelectorStyles from '../../../../../nlp/constants/selectorStyles'
import LanguageSelector from 'uiKit/LaguageSelector/LaguageSelector'
import { useFacebookActivePagesContext } from '../FacebookActivePages/context'
import { PlusIcon } from 'uiKit/icons/Icons'
import CustomOption from '../CustomFacebookPageSelect/CustomFacebookPageSelect'

interface Props {
  classes: any
  pages: any
  setValue: (a: string, b: any) => void
  control: any
}

export const NewPageGeneral: React.FC<Props> = props => {
  const { classes } = props
  const [pagesForSelect, setPagesForSelect] = useState([])
  const [defaultLanguage, setDefaultLanguage] = useState([])
  const { pages } = useFacebookActivePagesContext()

  useEffect(() => {
    const createPagesForSelect = props.pages?.map(page => ({
      label: page.name,
      value: page.id,
      isSubscribed: page['is_webhooks_subscribed'],
      img: page?.picture?.data?.url,
      isSubscribedToCurrentPage: pages?.some(fbPage => fbPage?.pageId?.toString() === page?.id),
    }))

    setPagesForSelect(createPagesForSelect)
  }, [props.pages])

  const changeSelect = e => {
    const page = props.pages.filter(page => page.id === e.value)
    props.setValue('pageId', page[0].id)
    props.setValue('pageName', page[0].name)
    props.setValue('pictureUrl', page[0].picture.data.url)
  }

  const handleSelectDefaultLanguage = e => {
    props.setValue('defaultLanguage', {
      fullName: e.label,
      shortName: e.value,
      isRtl: e.rtl,
      id: e.id,
    })
    setDefaultLanguage(e)
  }

  const Menu = props => {
    return (
      <components.Menu {...props}>
        {props.children}
        <div
          onClick={() => window.open('https://www.facebook.com/pages/creation', '_blank')}
          className={classes.button}>
          <PlusIcon height="10" width="10" color="#1658F3"/>
          <span>Create new Facebook page</span>
        </div>
      </components.Menu>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <p className={classes.itemLabel}>Facebook page</p>
        <Select
          styles={SelectorStyles}
          options={pagesForSelect}
          placeholder={'Select Facebook page'}
          onChange={changeSelect}
          components={{ Option: CustomOption, Menu }}
        />
      </div>
      <div className={classes.item}>
        <p className={classes.itemLabel}>Default language</p>
        <LanguageSelector
          onChange={handleSelectDefaultLanguage}
          value={defaultLanguage}
          styles={SelectorStyles}
          predefineValue={true}
        />
      </div>
      <div className={classes.item}>
        <p className={classes.itemLabel}>
          Greeting text
          <img
            style={{ cursor: 'pointer', paddingLeft: '3px', height: 12 }}
            data-tip
            data-for={'tooltip-greeting'}
            src="/images/platform/information-button.svg"
            alt="info icon"
          />
        </p>
        <ReactTooltip className={classes.tooltip} place="bottom" type="light" effect="solid" id={'tooltip-greeting'}>
          The greeting text of your bot's Messenger profile allows you to specify the message people will see on the
          welcome screen of your bot. You can personalize the greeting text using the person's name. You can use the
          following template strings:{' '}
          <span style={{ color: '#1658F3' }}>{'{{user_first_name}}, {{user_last_name}}, {{user_full_name}}'}</span>
        </ReactTooltip>
        <Controller
          name="greetingText"
          control={props.control}
          render={({ onChange, value }) => (
            <textarea
              className={classes.textarea}
              defaultValue={value}
              placeholder={'Hello, this is a basic example of greeting text of your bot which will be displayed here.'}
              onChange={onChange}
            />
          )}
        />
      </div>
    </div>
  )
}
