import React, { useState } from 'react'
import moment from 'moment'

const DateControlContext = React.createContext({
  startDate: moment().subtract(1, 'week').add(1, 'day'),
  endDate: moment(),
  onChange: null,
})

const DateControlProvider = props => {
  const [startDate, setStartDate] = useState(moment().subtract(1, 'week').add(1, 'day'))
  const [endDate, setEndDate] = useState(moment())

  const onChange = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <DateControlContext.Provider
      value={{
        startDate,
        endDate,
        onChange,
      }}>
      {props.children}
    </DateControlContext.Provider>
  )
}

export { DateControlProvider, DateControlContext }
