import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Controller } from 'react-hook-form/dist/index.ie11'
import SelectorStyles from '../../../../../nlp/constants/selectorStyles'
import LanguageSelector from 'uiKit/LaguageSelector/LaguageSelector'

interface Props {
  page: any
  classes: any
  control: any
  setValue: (a: string, b: any) => void
}

export const EditPageGeneral: React.FC<Props> = props => {
  const { classes } = props
  const [defaultLanguage, setDefaultLanguage] = useState(null)

  useEffect(() => {
    const defaultLanguage = {
      label: props.page.defaultLanguage?.fullName,
      value: props.page.defaultLanguage?.shortName,
      rtl: props.page.defaultLanguage?.isRtl,
      id: props.page.defaultLanguage?.id,
    }
    setDefaultLanguage(defaultLanguage)
  }, [props.page.defaultLanguage])

  const handleSelectDefaultLanguage = e => {
    props.setValue('defaultLanguage', {
      fullName: e.label,
      shortName: e.value,
      isRtl: e.rtl,
      id: e.id,
    })
    setDefaultLanguage(e)
  }

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <p className={classes.itemLabel}>Facebook page</p>
        <div className={classes.nameBlock}>
          <img
            src={props.page.pictureUrl}
            alt="page image"
            onError={event => {
              // @ts-ignore
              event.target.src = '/images/platform/default_profile.jpeg'
              // @ts-ignore
              event.onerror = null
            }}
          />
          <span>{props.page.pageName}</span>
        </div>
      </div>
      <div className={classes.item}>
        <p className={classes.itemLabel}>Default language</p>
        <LanguageSelector
          onChange={handleSelectDefaultLanguage}
          value={defaultLanguage}
          styles={SelectorStyles}
        />
      </div>
      <div className={classes.item}>
        <p className={classes.itemLabel}>
          Greeting text
          <img
            style={{ cursor: 'pointer', paddingLeft: '3px', height: 12 }}
            data-tip
            data-for={'tooltip-greeting'}
            src="/images/platform/information-button.svg"
            alt="info icon"
          />
        </p>
        <ReactTooltip className={classes.tooltip} place="bottom" type="light" effect="solid" id={'tooltip-greeting'}>
          The greeting text of your bot's Messenger profile allows you to specify the message people will see on the
          welcome screen of your bot. You can personalize the greeting text using the person's name. You can use the
          following template strings:{' '}
          <span style={{ color: '#1658F3' }}>{'{{user_first_name}}, {{user_last_name}}, {{user_full_name}}'}</span>
        </ReactTooltip>
        <Controller
          name="greetingText"
          control={props.control}
          value={props.page.greetingText}
          render={({ onChange, value }) => (
            <textarea
              className={classes.textarea}
              defaultValue={value}
              placeholder={'Hello, this is a basic example of greeting text of your bot which will be displayed here.'}
              onChange={onChange}
            />
          )}
        />
      </div>
    </div>
  )
}
