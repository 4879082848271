import { addNewFlow, saveFlows } from '../actions/flows'
import { saveAtoms } from '../actions/atoms'

import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'

import { BASE_URL } from '../../../configs'

export function getFlows(botId) {
  fetch(BASE_URL + '/bot/' + botId + '/flow', {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      saveFlows(json)
      saveAtoms(json)
    })
    .catch(function(error) {
      alertError('Sorry but something is going wrong please ping support!')
    })
}

export function createNewFlow(botId, flow) {
  fetch(BASE_URL + '/bot/' + botId + '/flow', {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(flow),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Flow is created successfully.')
      addNewFlow(json)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function deleteFlow(flowId, botId, callback) {
  fetch(BASE_URL + '/bot/' + botId + '/flow/' + flowId, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'DELETE',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Flow is deleted successfully.')
      getFlows(botId)
      callback()
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function changeFlowStatus(flowId, botId, disable) {
  fetch(BASE_URL + '/flow/' + flowId + '?disable=' + disable, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'PUT',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Flow status is changed successfully.')
      getFlows(botId)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}
