import React, { useEffect, useState } from 'react'
import moment from 'moment'

import TrainButton from '../TrainButton'
import Table from 'uiKit/table/Table'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import IntentSelect from '../IntentSelect'
import IntentModal from '../IntentModal'
import { ExternalLinkIcon } from 'uiKit/icons/ExternalLinkIcon'
import { deepCopyFunction } from 'helpers/deepCopyFunction'
import { allTabTitles, notHandledTabTitles } from '../../constants/tableTitles'
import { ALL } from '../../constants/tabs'
import { HOME_PATH } from 'configs'

import * as S from './TrainingTable.style'

interface Props {
  activeBot?: any
  nlpRequests?: any
  tab: string
  intents: any
  defaultIntent: any
  getRequestsData: any
}

export const TrainingTable: React.FC<Props> = ({
  nlpRequests,
  tab,
  activeBot,
  intents,
  defaultIntent,
  getRequestsData,
}) => {
  const [requests, setRequests] = useState(deepCopyFunction(nlpRequests.data))
  const [open, setOpen] = useState(false)
  const [trainUserPhrase, setTrainUserPhrase] = useState(null)

  useEffect(() => {
    setRequests(deepCopyFunction(nlpRequests.data))
  }, [nlpRequests.data])

  const handleSelectIntent = (option, request) => {
    const newRequests = [...requests]?.map(req => {
      if (req.id === request.id) {
        req.triggeredIntentId = option.value
        req.triggeredIntentName = option.label
        req.trained = false
      }
      return req
    })

    setRequests(newRequests)
  }

  const handleOpenModal = request => {
    setTrainUserPhrase(request)
    setOpen(true)
  }

  const handleOpenLink = userId => {
    const link = `${HOME_PATH}/bot/${activeBot.id}/support/${userId}`
    window.open(link, '_blank')
  }

  return (
    <>
      <Table titles={tab === ALL ? allTabTitles : notHandledTabTitles}>
        {requests?.map((nlpRequest, index) => (
          <TableRow key={nlpRequest.id} index={index}>
            <TableCell style={{ maxWidth: 200 }}>{nlpRequest.userSay}</TableCell>
            <TableCell style={{ maxWidth: 30 }}>
              <S.ExternalLinkWrap onClick={() => handleOpenLink(nlpRequest.userId)}>
                <ExternalLinkIcon />
              </S.ExternalLinkWrap>
            </TableCell>
            <TableCell style={{ maxWidth: 190 }}>
              {nlpRequest.trained ? (
                nlpRequest.triggeredIntentName
              ) : (
                <S.SelectorWrap>
                  <IntentSelect
                    value={{ value: nlpRequest.triggeredIntentId }}
                    onChange={option => handleSelectIntent(option, nlpRequest)}
                    isCreateNewOption={true}
                    trainUserPhrase={nlpRequest}
                    callback={getRequestsData}
                  />
                </S.SelectorWrap>
              )}
            </TableCell>
            <TableCell style={{ maxWidth: 80 }}>
              <TrainButton
                onClick={() => handleOpenModal(nlpRequest)}
                isTrained={nlpRequest.trained}
                isDefault={nlpRequest.triggeredIntentId === defaultIntent?.id}
              />
            </TableCell>
            {tab === ALL && (
              <TableCell style={{ maxWidth: 80 }}>
                {Number.parseFloat(nlpRequest.detectionConfidence).toFixed(2)?.replace(/\.0+$/, '')}
              </TableCell>
            )}
            <TableCell style={{ maxWidth: 120 }}>
              {moment(nlpRequest.createdDateTime).format('DD.MM.YY HH:mm A')}
            </TableCell>
          </TableRow>
        ))}
      </Table>
      {open && (
        <IntentModal
          open={open}
          intents={intents}
          modalIntentId={trainUserPhrase?.triggeredIntentId}
          isFaqModal={true}
          onClose={() => setOpen(false)}
          trainUserPhrase={trainUserPhrase}
          callback={getRequestsData}
        />
      )}
    </>
  )
}
