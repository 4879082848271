/**
 * Created by Admin on 25.05.2018.
 */
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import { ATHENS_GRAY, MISCHKA_GRAY } from '../../constants/colors'

const styles = theme => ({
  primary: {
    padding: '10px 16px',
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: '1px solid',
    outline: 'none',
    cursor: 'pointer',
    borderRadius: 10,
    lineHeight: 'normal',
    fontFamily: 'Lato',
    minWidth: 78,
    fontSize: 14,
    '&:hover': {},
    '&:disabled': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },
  secondary: {
    padding: '10px 16px',
    color: theme.palette.primary.main,
    background: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
    border: '1px solid',
    outline: 'none',
    cursor: 'pointer',
    borderRadius: 10,
    lineHeight: 'normal',
    fontFamily: 'Lato',
    minWidth: 78,
    fontSize: 14,
    '&:hover': {},
    '&:disabled': {
      backgroundColor: ATHENS_GRAY,
      color: MISCHKA_GRAY,
      borderColor: MISCHKA_GRAY,
    },
  },
  warning: {
    padding: '10px 16px',
    color: '#FF6666',
    background: 'none',
    borderColor: 'none',
    border: '0px',
    outline: 'none',
    cursor: 'pointer',
    borderRadius: 10,
    lineHeight: 'normal',
    fontFamily: 'Lato',
    minWidth: 78,
    fontSize: 14,
  },
  default: {
    padding: '8px 16px',
    background: theme.palette.primary.contrastText,
    border: '1px solid',
    borderColor: theme.palette.default.gray,
    color: theme.palette.text.header,
    borderRadius: 10,
    fontSize: 14,
    lineHeight: 1,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noStyle: {
    color: theme.palette.text.header,
    textDecoration: 'underline',
    border: 'none',
    background: 'transparent',
    padding: '0',
    fontSize: 14,
    fontWeight: '600',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  sizeSm: {
    padding: '8px 16px',
  },
  sizeLg: {
    padding: '14px 28px',
  },
  cancel: {
    backgroundColor: 'unset',
    outline: 'none',
    cursor: 'pointer',
    fontSize: 14,
    color: '#616581',
    padding: '8px 28px',
    border: 'none',
  },
  empty: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
})

class SubmitButton extends React.Component {
  render() {
    const { classes, type, size, className, dataAutotest } = this.props
    const classButton = `${type ? classes[type] : classes.primary} ${
      this.props.empty && classes.empty
    }`
    const buttonSizeClass =
      size === 'sm' ? classes.sizeSm : size === 'lg' ? classes.sizeLg : ''

    return (
      <button
        disabled={this.props.disabled}
        className={`${classButton} ${buttonSizeClass} ${className}`}
        onClick={e => this.props.onClick && this.props.onClick(e)}
        data-autotest={dataAutotest || null}
        style={this.props.styles}>
        {this.props.title}
      </button>
    )
  }
}

SubmitButton.propTypes = {
  classes: PropTypes.object,
  styles: PropTypes.object,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  empty: PropTypes.bool,
  onClick: PropTypes.func,
}

export default withStyles(styles, { withTheme: true })(SubmitButton)
