import React from 'react'

import {
  BroadcastIcon,
  DashboardIcon,
  FlowsIcon,
  NlpIcon,
  SettingsIcon,
  SupportIcon,
  WidgetIcon,
  AnalyticsIcon,
} from '../uiKit/icons/Icons'

import Dashboard from '../tabs/dashboard/Dashboard'
import Builder from '../tabs/flowBuilder/Builder'
import NLP from '../tabs/nlp/NlpContainer'
import ChatContainer from '../tabs/support/SupportContainer'
import Broadcasts from '../tabs/broadcast/Broadcasts'
import Settings from '../tabs/settings/Settings'
import WidgetContainer from '../tabs/widget/WidgetContainer'
import AnalyticsContainer from '../tabs/analytics/AnalyticsContainer'

import { ComponentNames } from '../constants/componentNames'

export const mainRoutes = [
  {
    path: 'dashboard',
    icon: <DashboardIcon width={40} height={20} color="#8EB0F8" />,
    name: ComponentNames.Dashboard,
    component: Dashboard,
  },
  {
    path: 'analytics',
    icon: <AnalyticsIcon width={40} height={20} color="#8EB0F8" />,
    name: 'Analytics',
    component: AnalyticsContainer,
  },
  {
    path: 'flows',
    icon: <FlowsIcon width={40} height={20} color="#8EB0F8" />,
    name: ComponentNames.Flows,
    component: Builder,
  },
  {
    path: 'nlp',
    icon: <NlpIcon width={40} height={20} color="#8EB0F8" />,
    name: ComponentNames.NLP,
    component: NLP,
  },
  {
    path: 'support',
    icon: <SupportIcon width={40} height={20} color="#8EB0F8" />,
    name: ComponentNames.Support,
    component: ChatContainer,
  },
  {
    path: 'broadcast',
    icon: <BroadcastIcon width={40} height={20} color="#8EB0F8" />,
    name: ComponentNames.Broadcasts,
    component: Broadcasts,
  },
  {
    path: 'widget',
    icon: <WidgetIcon width={40} height={20} color="#8EB0F8" />,
    name: ComponentNames.Widget,
    component: WidgetContainer,
  },
  {
    path: 'settings',
    icon: <SettingsIcon width={40} height={20} color="#8EB0F8" />,
    name: ComponentNames.BotSettings,
    component: Settings,
  },
]
