/**
 * Created by Admin on 17.04.2018.
 */
import React from 'react'

import { TextIcon } from '../../../../uiKit/icons/TextIcon'
import { GalleryIcon } from '../../../../uiKit/icons/GalleryIcon'
import { AttachmentMessageIcon } from '../../../../uiKit/icons/AttachmentMessageIcon'
import { TypingIcon } from '../../../../uiKit/icons/TypingIcon'

import {
  CONDITIONAL_REDIRECT_TYPE,
  RANDOM_REDIRECT_TYPE,
  SAVE_USER_INPUT_TYPE,
  SET_ATTRIBUTE_TYPE,
  TEXT_TYPE,
  USER_INPUT_TYPE,
  API_CALL_TYPE,
} from '../../constants/messageTypes'

export const availableDefaultMessages = [
  {
    name: 'Text',
    icon: <TextIcon width="16" height="16" color="#B0B2C0" />,
    iconLarge: <TextIcon width="24" height="24" color="#5A98F7" />,
    description: 'Send a text in chat',
    newMessage: {
      texts: [''],
      enableReaction: false,
      type: TEXT_TYPE,
    },
  },
  {
    name: 'Gallery',
    icon: <GalleryIcon width="16" height="16" color="#B0B2C0" />,
    iconLarge: <GalleryIcon width="24" height="24" color="#5A98F7" />,
    type: 'gallery',
    description: 'Send a carousel of options in chat',
    newMessage: {
      attachment: {
        type: 'template',
        payload: {
          template_type: 'generic',
          elements: [
            {
              title: '',
              subtitle: '',
              image_url: '',
              buttons: [],
            },
          ],
        },
      },
      enableReaction: false
    },
    iconName: 'TextIcon',
  },
  {
    name: 'Attachment',
    icon: <AttachmentMessageIcon width="16" height="16" color="#B0B2C0" />,
    iconLarge: <AttachmentMessageIcon width="24" height="24" color="#5A98F7" />,
    type: 'attachment',
    description: 'Send an image, video or GIF in chat',
    newMessage: {
      attachment: {
        type: 'image',
        payload: {
          url: '',
        },
      },
      enableReaction: false
    },
    iconName: 'TextIcon',
  },
  {
    name: 'Typing',
    type: 'typing',
    icon: <TypingIcon width="16" height="16" color="#B0B2C0" />,
    iconLarge: <TypingIcon width="24" height="24" color="#5A98F7" />,
    description: 'Send typing indicator',
    newMessage: {
      senderAction: 'typing_on',
      time: 3,
    },
    iconName: 'TextIcon',
  },
]

export const availableOtherMessages = [
  {
    name: 'Media template',
    newMessage: {
      attachment: {
        type: 'image',
        payload: {
          url: '',
        },
      },
    },
  },
  {
    name: 'User Input',
    type: USER_INPUT_TYPE,
    newMessage: {
      type: USER_INPUT_TYPE,
      parameter: '',
      next_atom: '',
    },
  },
]

export const randomRedirectInitialState = {
  type: RANDOM_REDIRECT_TYPE,
  randomRedirect: {
    randomAtoms: [
      {
        atomName: '',
        number: 1,
      },
    ],
    sendFirstAtomOnlyOnce: false,
  },
}

export const setAttributeMessage = {
  type: SET_ATTRIBUTE_TYPE,
  attributes: [
    {
      id: '',
      value: '',
      name: '',
    },
  ],
}

export const saveUserInput = {
  type: SAVE_USER_INPUT_TYPE,
  saveUserInput: {
    validation: 'NONE',
    customValidationRegex: null,
    onValidationSuccessAtomId: null,
    onValidationFailAtomId: null,
    saveAsAttribute: null,
  },
}

export const smartRedirect = {
  type: CONDITIONAL_REDIRECT_TYPE,
  conditionalRedirect: {
    conditionGroups: null,
    defaultRedirect: null,
  },
}

export const conditionGroup = {
  expressions: [
    {
      attribute: null,
      operator: 'IS',
      value: null,
    },
  ],
  conjunction: 'AND',
  redirectTo: null,
}

export const apiCall = {
  type: API_CALL_TYPE,
  apiCall: {
    addUserData: false,
    body: null,
    headers: [],
    method: 'GET',
    url: null,
    attributes: [],
  },
}
