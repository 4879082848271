export const allTabTitles = [
  { title: 'User phrase', maxWidth: 200 },
  { title: '', maxWidth: 30 },
  { title: 'Intent', maxWidth: 190 },
  { title: '', maxWidth: 80 },
  { title: 'Confidence', maxWidth: 80 },
  { title: 'Sent at', maxWidth: 120 },
]

export const notHandledTabTitles = [
  { title: 'User phrase', maxWidth: 200 },
  { title: '', maxWidth: 30 },
  { title: 'Intent', maxWidth: 190 },
  { title: '', maxWidth: 80 },
  { title: 'Sent at', maxWidth: 120 },
]
