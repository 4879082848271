import React, { useContext, useEffect, useState } from 'react'

import { CustomRadioButton } from 'uiKit/CustomRadioButton/CustomRadioButton'
import LanguageSelector from 'uiKit/LaguageSelector/LaguageSelector'
import Search from 'uiKit/Search/Search'
import Pagination from 'uiKit/table/Pagination'
import LoaderScreen from 'uiKit/loaders/loaderScreen'
import NoChartData from 'uiKit/NoChartData'

import IntentSelect from '../IntentSelect'
import TrainingTable from '../TrainingTable'

import { DateControlContext } from '../../../../contexts/DateControlContext'
import { getAllPagingIntents } from '../../api/nlp'
import { getNlpRequest } from '../../api/training'
import useDebounce from '../../../../hooks/useDebounce'
import SelectorStyles from '../../constants/selectorStyles'
import { defaultFallbackIntent, defaultValueIntent, defaultValueLanguage } from '../../constants/defaultValues'
import { NOT_HANDLED, ALL } from '../../constants/tabs'
import { SIZE } from '../../constants/paginationSize'
import { IntentType, NlpRequestsType } from 'models/IntentsType'
import { clearIntents } from '../../actions/intents'

import * as S from './TrainingContainer.style'

interface Props {
  activeBot: any
  nlpRequests: NlpRequestsType
  intents: IntentType[]
}

export const TrainingContainer: React.FC<Props> = ({ activeBot, nlpRequests, intents }) => {
  const [tab, setTab] = useState(NOT_HANDLED)
  const [selectedLanguage, setSelectedLanguage] = useState(defaultValueLanguage)
  const [searchValue, setSearchValue] = useState('')
  const [selectedIntent, setSelectedIntent] = useState(null)
  const [page, setPage] = useState(0)
  const [defaultIntent, setDefaultIntent] = useState(null)
  const [loading, setLoading] = useState(true)
  const { startDate, endDate } = useContext(DateControlContext)
  const debouncedSearch = useDebounce(searchValue, 500)

  useEffect(() => {
    getAllPagingIntents(activeBot.id)
    return () => {
      clearIntents()
    }
  }, [])

  useEffect(() => {
    const defaultIntent = intents?.find(({ name }) => name === defaultFallbackIntent)

    if (intents?.length && defaultIntent && !selectedIntent) {
      const selectedIntent = { value: defaultIntent?.id || '', label: defaultIntent?.name || '' }

      setDefaultIntent(defaultIntent)
      setSelectedIntent(selectedIntent)
      setTab(NOT_HANDLED)
    }
  }, [intents])

  useEffect(() => {
    if (selectedIntent) {
      setPage(0)
      getRequestsData(0)
    }
  }, [debouncedSearch, selectedIntent, tab, selectedLanguage, endDate])

  const getRequestsData = page => {
    getNlpRequest(
      activeBot.id,
      page,
      SIZE,
      selectedLanguage.value,
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
      selectedIntent.value,
      debouncedSearch,
    ).then(() => {
      setLoading(false)
    })
  }

  const handleChangeTab = tab => {
    if (tab === NOT_HANDLED) {
      setSelectedIntent({ value: defaultIntent?.id, label: defaultIntent?.name })
    } else {
      setSelectedIntent(defaultValueIntent)
    }
    setTab(tab)
  }

  const handlePage = (event, page) => {
    setPage(page)
    getRequestsData(page)
  }

  return (
    <S.TrainingContainer>
      <S.Header>
        <S.TitleWrap>
          <S.Title>Training</S.Title>
          <CustomRadioButton options={[NOT_HANDLED, ALL]} selected={tab} onChange={handleChangeTab} />
        </S.TitleWrap>

        <S.FiltersWrap>
          {tab === ALL && (
            <S.SelectorWrap>
              <IntentSelect value={selectedIntent} onChange={setSelectedIntent} defaultValue={defaultValueIntent} />
            </S.SelectorWrap>
          )}
          <S.SearchWrap>
            <Search
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={'Search by keyword'}
              inputStyle={{ borderRadius: 10, height: '45px', padding: '14px 24px', minWidth: 200 }}
            />
            <S.SelectorWrap>
              <LanguageSelector
                onChange={setSelectedLanguage}
                value={selectedLanguage}
                defaultValue={defaultValueLanguage}
                styles={SelectorStyles}
              />
            </S.SelectorWrap>
          </S.SearchWrap>
        </S.FiltersWrap>
      </S.Header>

      {loading && <LoaderScreen />}

      {!loading &&
        (nlpRequests?.data?.length ? (
          <>
            <TrainingTable tab={tab} defaultIntent={defaultIntent} getRequestsData={() => getRequestsData(page)} />
            <Pagination page={page} rowsPerPage={SIZE} count={nlpRequests?.totalCount || 0} onChangePage={handlePage} />
          </>
        ) : (
          <NoChartData text={'No data during selected time range'} />
        ))}
    </S.TrainingContainer>
  )
}
