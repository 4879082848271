/* eslint-disable */
import React from 'react'

export const ArrowRotate = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.6662 2.33172V7.33172M17.6662 7.33172H12.6662M17.6662 7.33172L13.8079 3.69838C12.6584 2.54817 11.1662 1.80271 9.55621 1.57434C7.94618 1.34597 6.30555 1.64706 4.88154 2.43225C3.45752 3.21744 2.32728 4.44418 1.66113 5.92761C0.994972 7.41104 0.828997 9.0708 1.18821 10.6568C1.54743 12.2427 2.41237 13.669 3.6527 14.7206C4.89304 15.7722 6.44156 16.3922 8.06492 16.4872C9.68829 16.5822 11.2985 16.147 12.653 15.2471C14.0075 14.3473 15.0329 13.0316 15.5746 11.4984"
        stroke="#1658F3"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
