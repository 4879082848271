import React, { useContext, useState } from 'react'
import moment from 'moment'

import DateRange from '../DateRange'
import { DateControlContext } from 'contexts/DateControlContext'

import * as S from './DateControl.styles'

const FREQUENCIES = {
  week: 'week',
  month: 'month',
  custom: 'custom',
}

export const DateControl = () => {
  const [frequency, setFrequency] = useState(FREQUENCIES.week)
  const { startDate, endDate, onChange } = useContext(DateControlContext)

  const handleChange = frequency => {
    let newStartDate, newEndDate
    switch (frequency) {
      case FREQUENCIES.week: {
        newStartDate = moment().subtract(1, 'week').add(1, 'day')
        newEndDate = moment()
        break
      }
      case FREQUENCIES.month: {
        newStartDate = moment().subtract(1, 'month').add(1, 'day')
        newEndDate = moment()
        break
      }
      case FREQUENCIES.custom: {
        newStartDate = startDate
        newEndDate = endDate
        break
      }
    }

    onChange({ startDate: newStartDate, endDate: newEndDate })
    setFrequency(frequency)
  }

  const handleCustomDate = (startDate, endDate) => {
    onChange({ startDate, endDate })
  }

  return (
    <S.DateControl>
      <S.Frequency>
        <S.FrequencyButton onClick={() => handleChange(FREQUENCIES.week)} isSelected={frequency === FREQUENCIES.week}>
          Last 7 days
        </S.FrequencyButton>
        <S.FrequencyButton onClick={() => handleChange(FREQUENCIES.month)} isSelected={frequency === FREQUENCIES.month}>
          Last 30 days
        </S.FrequencyButton>
        <S.FrequencyButton
          onClick={() => handleChange(FREQUENCIES.custom)}
          isSelected={frequency === FREQUENCIES.custom}>
          Custom
        </S.FrequencyButton>
      </S.Frequency>
      {frequency === FREQUENCIES.custom && (
        <DateRange startDate={startDate} endDate={endDate} setDate={handleCustomDate} />
      )}
    </S.DateControl>
  )
}
