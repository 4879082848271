import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Paragraph from '../../../../uiKit/texts/Paragraph'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import { BACKEND_URL, SERVER_URL } from '../../../../configs'
import { alertSuccess } from '../../../../api'

import classes from './styles.module.scss'
import {WidgetSettingsType} from '../../../../models/WidgetSettingsTypes'
import React from 'react'

interface Props {
  widgetSettings: WidgetSettingsType
  activeBot: any
}

const CopySnippet: React.FC<Props> = ({ widgetSettings, activeBot }) => {
  // eslint-disable-next-line max-len
  const scriptSnipped = `<script type="text/javascript" id="chatbot-initials-script" src="${SERVER_URL}/widget/script-chatbot.js" data-server-url="${BACKEND_URL}" data-bot-id="${activeBot?.id}"></script>`

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(scriptSnipped)
    alertSuccess('Copied!')
  }

  return (
    <div>
      <Paragraph style={{ margin: '4px 0 8px 0' }}>Copy widget code snippet to clipboard</Paragraph>
      <pre className={classes.snippet}>
        {`<script type="text/javascript" 
        id="chatbot-initials-script" 
        src="${widgetSettings?.scriptChatbotUrl}" 
        data-server-url="${BACKEND_URL}" 
        data-bot-id="${activeBot?.id}">
</script>`}
      </pre>
      <SubmitButton title="Copy to clipboard" onClick={handleCopyToClipboard} />
    </div>
  )
}

const mapStateToProps = (state: { activeBot: any; widgetSettings: WidgetSettingsType }) => ({
  activeBot: state.activeBot,
  widgetSettings: state.widgetSettings,
})

export default withRouter(connect(mapStateToProps)(CopySnippet))
