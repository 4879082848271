export const PRIMARY = 'primary'

export const KLEIN_BLUE = '#0030A0'
export const CAMARONE_GREEN = '#006401'
export const GEM_BLUE = '#3B17A2'
export const CINNAMON_YELLOW = '#7D4400'
export const TAMARILLO_RED = '#A21010'
export const SPUN_PEARL_GREY = '#B0B2C0'
export const PERMISSION_RED = '#ff624c'
export const WHITE = '#ffffff'
export const RIBBON_BLUE = '#1658f3'
export const DULL_LAVANDER_BLUE = '#9bb3e4'
export const DANUBE_BLUE = '#547ed0'
export const CORNFLOWER_BLUE = '#5A98F7'
export const BLUE = '#0000FF'
export const COMET_GREY = '#616581'
export const SELAGO_BLUE = '#f3f7fe'
export const SHARK_BLACK = '#212529'
export const ATHENS_GRAY = '#fafafb'
export const MISCHKA_GRAY = '#D7D8DF'
export const OXFORD_BLUE = '#354052'
export const FIORD_BLUE = '#3a3f62'
export const ATHENS_GRAY_DARK = '#ebebef'
export const SOLITUDE = '#dfecff'
export const MALACHITE_GREEN = '#11C314'
export const ROYAL_BLUE = '#3a71f1'
export const GHOST_GRAY = '#C7CAD6'
export const SELAGO_GRAY = '#F7FAFE'
export const HAWKES_BLUE = '#EEF5FE'
export const PERIWINKLE = '#CADFFF'
export const MINE_SHAFT = '#393939'
export const PALE_SKY = '#6D7686'
